// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by graphParser.

export default class graphVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by graphParser#file.
	visitFile(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#referenceFile.
	visitReferenceFile(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#expressionFile.
	visitExpressionFile(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockContent.
	visitBlockContent(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#systemDescription.
	visitSystemDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#systemElementDescription.
	visitSystemElementDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#contextDescription.
	visitContextDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#contextElementDescription.
	visitContextElementDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#externalFunctionDeclaration.
	visitExternalFunctionDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#importDefinition.
	visitImportDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#whenDescription.
	visitWhenDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeDefinition.
	visitTypeDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#functionDescription.
	visitFunctionDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#contextFunctionDescription.
	visitContextFunctionDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockDeclaration.
	visitBlockDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeDeclaration.
	visitNodeDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionDeclaration.
	visitDigressionDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionContent.
	visitDigressionContent(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#comment.
	visitComment(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeContent.
	visitNodeContent(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeOnExitSection.
	visitNodeOnExitSection(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeOnExitSectionItem.
	visitNodeOnExitSectionItem(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#onExitDoWithConfident.
	visitOnExitDoWithConfident(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeOnDigressionReturnSection.
	visitNodeOnDigressionReturnSection(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeTransitionSection.
	visitNodeTransitionSection(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#namedTransitionDefinition.
	visitNamedTransitionDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#transitionDefinition.
	visitTransitionDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#transitionDefinitionOnMessage.
	visitTransitionDefinitionOnMessage(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#timeoutTransitionDefinition.
	visitTimeoutTransitionDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#conditionalTransitionDefinition.
	visitConditionalTransitionDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#unconditionalTransitionDefinition.
	visitUnconditionalTransitionDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#doSection.
	visitDoSection(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockBody.
	visitBlockBody(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#oneOrGroupedCommand.
	visitOneOrGroupedCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeCommand.
	visitNodeCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#cycleCommand.
	visitCycleCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#statement.
	visitStatement(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#statementBody.
	visitStatementBody(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#logExpressionTypeCommand.
	visitLogExpressionTypeCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#enableDigression.
	visitEnableDigression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#disableDigression.
	visitDisableDigression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#saveDigression.
	visitSaveDigression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#applyDigression.
	visitApplyDigression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionSpec.
	visitDigressionSpec(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#exitCommand.
	visitExitCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#returnCommand.
	visitReturnCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#variableDefinition.
	visitVariableDefinition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#variableDeclaration.
	visitVariableDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeDescription.
	visitTypeDescription(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#conditionalCommand.
	visitConditionalCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#foreachCommand.
	visitForeachCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#waitCommand.
	visitWaitCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#selectedActivatedTransitionList.
	visitSelectedActivatedTransitionList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#allActivatedTransitionList.
	visitAllActivatedTransitionList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#gotoCommand.
	visitGotoCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#setCommand.
	visitSetCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#assignOpCommand.
	visitAssignOpCommand(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#lValueReferenceExpression.
	visitLValueReferenceExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#lValueParenthesizedExpression.
	visitLValueParenthesizedExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#lValueDotAccessExpression.
	visitLValueDotAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#lValueLiteralIndexAccessExpression.
	visitLValueLiteralIndexAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#lValueExpressionIndexAccessExpression.
	visitLValueExpressionIndexAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#ternaryOpExpression.
	visitTernaryOpExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#expressionIndexAccessExpression.
	visitExpressionIndexAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nullCollateExpression.
	visitNullCollateExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#booleanLiteralExpression.
	visitBooleanLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#numberLiteralExpression.
	visitNumberLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#binaryOpExpression.
	visitBinaryOpExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#optionalCallDotAccessExpression.
	visitOptionalCallDotAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#castExpression.
	visitCastExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#builtinFunctionCallExpression.
	visitBuiltinFunctionCallExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#parenthesizedExpression.
	visitParenthesizedExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#callDotAccessExpression.
	visitCallDotAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nullLiteralExpression.
	visitNullLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#isNullExpression.
	visitIsNullExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionControlExpression.
	visitDigressionControlExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#tupleLiteralExpression.
	visitTupleLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockFunctionCallExpression.
	visitBlockFunctionCallExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#referenceExpression.
	visitReferenceExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#optionalDotAccessExpression.
	visitOptionalDotAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#objectLiteralExpression.
	visitObjectLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#literalIndexAccessExpression.
	visitLiteralIndexAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#externFunctionCallExpression.
	visitExternFunctionCallExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#stringLiteralExpression.
	visitStringLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#slotFillingCallExpression.
	visitSlotFillingCallExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#isDefinedExpression.
	visitIsDefinedExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#unaryOpExpression.
	visitUnaryOpExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#dotAccessExpression.
	visitDotAccessExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#objectPropertyExpressionPart.
	visitObjectPropertyExpressionPart(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#externalFunctionCall.
	visitExternalFunctionCall(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockFunctionCall.
	visitBlockFunctionCall(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#slotFillingCall.
	visitSlotFillingCall(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#builtinFunctionCall.
	visitBuiltinFunctionCall(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#argumentInvokation.
	visitArgumentInvokation(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#argumentList.
	visitArgumentList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#namedArgumentList.
	visitNamedArgumentList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#positionalArgumentList.
	visitPositionalArgumentList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#positionalArgument.
	visitPositionalArgument(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#namedArgument.
	visitNamedArgument(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#parameterList.
	visitParameterList(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#parameter.
	visitParameter(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#builtinReference.
	visitBuiltinReference(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#localReference.
	visitLocalReference(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#contextReference.
	visitContextReference(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionReference.
	visitDigressionReference(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#whenId.
	visitWhenId(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#nodeId.
	visitNodeId(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#blockId.
	visitBlockId(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#digressionId.
	visitDigressionId(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#transitionName.
	visitTransitionName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#referenceName.
	visitReferenceName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#memberName.
	visitMemberName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#tagName.
	visitTagName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#factName.
	visitFactName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#fieldName.
	visitFieldName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#variableName.
	visitVariableName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#argumentName.
	visitArgumentName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#parameterName.
	visitParameterName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#propertyName.
	visitPropertyName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#builtinFunctionName.
	visitBuiltinFunctionName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#externalFunctionName.
	visitExternalFunctionName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#functionName.
	visitFunctionName(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#anyNumber.
	visitAnyNumber(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeEof.
	visitTypeEof(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#type_.
	visitType_(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#presentQuestionMark.
	visitPresentQuestionMark(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#noQuestionMark.
	visitNoQuestionMark(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#objectTypeElementSeparator.
	visitObjectTypeElementSeparator(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#predefinedTypeRef.
	visitPredefinedTypeRef(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#arrayType.
	visitArrayType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#stringLiteralType.
	visitStringLiteralType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#tupleType.
	visitTupleType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeReference.
	visitTypeReference(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#angleUnionType.
	visitAngleUnionType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#parenthesisedType.
	visitParenthesisedType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#objectType.
	visitObjectType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#unknownType.
	visitUnknownType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#numberType.
	visitNumberType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#booleanType.
	visitBooleanType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#stringType.
	visitStringType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#emptyType.
	visitEmptyType(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#propertySignature.
	visitPropertySignature(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#indexSignature.
	visitIndexSignature(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeAnnotation.
	visitTypeAnnotation(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by graphParser#typeAlias.
	visitTypeAlias(ctx) {
	  return this.visitChildren(ctx);
	}



}