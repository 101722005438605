// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,115,1006,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,
2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,
7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,
19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,
2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,
34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,
7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,
48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,
2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,
63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,
7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,
77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,
2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,
92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,99,
7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,104,2,105,7,
105,2,106,7,106,2,107,7,107,2,108,7,108,2,109,7,109,2,110,7,110,2,111,7,
111,2,112,7,112,2,113,7,113,2,114,7,114,2,115,7,115,2,116,7,116,2,117,7,
117,2,118,7,118,2,119,7,119,2,120,7,120,2,121,7,121,2,122,7,122,2,123,7,
123,2,124,7,124,2,125,7,125,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,1,1,1,1,1,1,
1,1,1,1,1,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,3,1,3,1,3,1,3,
1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,5,1,5,1,5,1,5,1,5,
1,5,1,5,1,5,1,5,1,6,1,6,1,7,1,7,5,7,309,8,7,10,7,12,7,312,9,7,1,7,1,7,1,
7,1,7,5,7,318,8,7,10,7,12,7,321,9,7,1,7,1,7,3,7,325,8,7,1,8,1,8,3,8,329,
8,8,1,9,1,9,3,9,333,8,9,1,10,1,10,1,10,1,11,1,11,1,11,1,12,1,12,1,13,1,13,
1,14,1,14,1,15,1,15,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,1,18,1,18,1,
18,1,18,1,18,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,21,1,21,1,21,
1,21,1,21,1,21,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,23,1,
23,1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,25,1,25,
1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,
26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,28,1,28,
1,28,1,28,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,
30,1,30,1,30,1,30,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,
1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,33,1,33,1,
33,1,33,1,33,1,33,1,33,1,33,1,34,1,34,1,34,1,35,1,35,1,35,1,35,1,35,1,36,
1,36,1,36,1,36,1,36,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,
38,1,38,1,38,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,39,1,40,1,40,1,40,1,40,
1,40,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,42,1,42,1,
42,1,42,1,42,1,43,1,43,1,43,1,43,1,43,1,43,1,43,1,44,1,44,1,44,1,44,1,44,
1,45,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,47,1,47,1,47,1,48,1,
48,1,48,1,48,1,48,1,48,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,50,1,50,1,50,
1,50,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,52,1,
53,1,53,1,53,1,53,1,54,1,54,1,54,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,56,
1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,
58,1,58,1,58,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,
1,59,1,59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,
61,1,61,1,61,1,61,1,61,1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,63,1,63,1,63,
1,63,1,64,1,64,1,64,1,64,1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,
66,1,66,1,66,1,66,1,66,1,66,1,66,1,67,1,67,1,67,1,67,1,67,1,68,1,68,1,68,
1,68,1,68,1,68,1,68,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,70,1,70,1,
70,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,1,71,
1,72,1,72,1,72,1,72,1,73,1,73,1,73,1,73,1,73,1,74,1,74,1,74,1,74,1,75,1,
75,1,75,1,75,1,75,1,76,1,76,1,76,1,76,1,76,1,77,1,77,1,77,1,77,1,77,1,78,
1,78,1,79,1,79,1,80,3,80,755,8,80,1,80,1,80,1,81,3,81,760,8,81,1,81,1,81,
1,82,1,82,1,82,5,82,767,8,82,10,82,12,82,770,9,82,1,82,3,82,773,8,82,1,82,
1,82,4,82,777,8,82,11,82,12,82,778,1,82,3,82,782,8,82,1,82,1,82,1,82,3,82,
787,8,82,1,83,1,83,1,83,5,83,792,8,83,10,83,12,83,795,9,83,3,83,797,8,83,
1,84,1,84,3,84,801,8,84,1,84,4,84,804,8,84,11,84,12,84,805,1,85,1,85,1,85,
1,85,1,85,1,85,1,85,1,85,1,85,3,85,817,8,85,1,86,1,86,1,86,1,86,1,86,1,86,
1,87,1,87,1,87,1,87,1,87,1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,
88,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,90,1,90,1,90,
1,90,1,91,1,91,1,91,1,91,1,91,1,92,1,92,1,93,1,93,1,94,1,94,1,95,1,95,1,
96,1,96,1,97,1,97,1,98,1,98,1,99,1,99,1,100,1,100,1,101,1,101,1,102,1,102,
1,103,1,103,1,104,1,104,1,105,1,105,1,106,1,106,1,107,1,107,1,108,1,108,
1,108,1,109,1,109,1,109,1,110,1,110,1,110,1,111,1,111,1,111,1,112,1,112,
1,112,1,113,1,113,1,113,1,114,1,114,1,114,1,114,3,114,914,8,114,1,115,1,
115,1,116,1,116,1,116,1,116,1,116,3,116,923,8,116,1,117,1,117,3,117,927,
8,117,1,118,3,118,930,8,118,1,118,1,118,5,118,934,8,118,10,118,12,118,937,
9,118,1,118,3,118,940,8,118,1,118,1,118,1,118,5,118,945,8,118,10,118,12,
118,948,9,118,3,118,950,8,118,1,119,1,119,1,120,1,120,1,121,1,121,3,121,
958,8,121,1,122,1,122,1,122,1,122,5,122,964,8,122,10,122,12,122,967,9,122,
1,122,1,122,1,123,1,123,1,123,1,123,1,123,5,123,976,8,123,10,123,12,123,
979,9,123,1,123,1,123,1,123,1,123,1,123,1,124,1,124,1,124,1,124,5,124,990,
8,124,10,124,12,124,993,9,124,1,124,1,124,1,124,1,124,1,124,1,125,4,125,
1001,8,125,11,125,12,125,1002,1,125,1,125,2,977,991,0,126,1,1,3,2,5,3,7,
4,9,5,11,6,13,0,15,7,17,0,19,0,21,0,23,0,25,8,27,9,29,10,31,11,33,12,35,
13,37,14,39,15,41,16,43,17,45,18,47,19,49,20,51,21,53,22,55,23,57,24,59,
25,61,26,63,27,65,28,67,29,69,30,71,31,73,32,75,33,77,34,79,35,81,36,83,
37,85,38,87,39,89,40,91,41,93,42,95,43,97,44,99,45,101,46,103,47,105,48,
107,49,109,50,111,51,113,52,115,53,117,54,119,55,121,56,123,57,125,58,127,
59,129,60,131,61,133,62,135,63,137,64,139,65,141,66,143,67,145,68,147,69,
149,70,151,71,153,72,155,73,157,74,159,75,161,76,163,77,165,0,167,0,169,
0,171,78,173,79,175,80,177,81,179,82,181,83,183,84,185,85,187,86,189,87,
191,88,193,89,195,90,197,91,199,92,201,93,203,94,205,95,207,96,209,97,211,
98,213,99,215,100,217,101,219,102,221,103,223,104,225,105,227,106,229,107,
231,108,233,109,235,110,237,111,239,0,241,0,243,0,245,112,247,113,249,114,
251,115,1,0,10,3,0,0,31,34,34,92,92,1,0,96,96,8,0,34,34,39,39,92,92,98,98,
102,102,110,110,114,114,116,116,1,0,48,57,1,0,49,57,2,0,69,69,101,101,2,
0,43,43,45,45,3,0,48,57,65,90,97,122,2,0,10,10,13,13,3,0,9,10,13,13,32,32,
1025,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,
0,0,0,0,15,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,
33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,
0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,
1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,
0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,
0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,
89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,0,
0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,0,
0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,1,0,0,0,
0,121,1,0,0,0,0,123,1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,0,0,0,0,
131,1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,0,0,0,141,
1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,0,0,0,0,151,1,
0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,0,0,0,0,159,1,0,0,0,0,161,1,0,
0,0,0,163,1,0,0,0,0,171,1,0,0,0,0,173,1,0,0,0,0,175,1,0,0,0,0,177,1,0,0,
0,0,179,1,0,0,0,0,181,1,0,0,0,0,183,1,0,0,0,0,185,1,0,0,0,0,187,1,0,0,0,
0,189,1,0,0,0,0,191,1,0,0,0,0,193,1,0,0,0,0,195,1,0,0,0,0,197,1,0,0,0,0,
199,1,0,0,0,0,201,1,0,0,0,0,203,1,0,0,0,0,205,1,0,0,0,0,207,1,0,0,0,0,209,
1,0,0,0,0,211,1,0,0,0,0,213,1,0,0,0,0,215,1,0,0,0,0,217,1,0,0,0,0,219,1,
0,0,0,0,221,1,0,0,0,0,223,1,0,0,0,0,225,1,0,0,0,0,227,1,0,0,0,0,229,1,0,
0,0,0,231,1,0,0,0,0,233,1,0,0,0,0,235,1,0,0,0,0,237,1,0,0,0,0,245,1,0,0,
0,0,247,1,0,0,0,0,249,1,0,0,0,0,251,1,0,0,0,1,253,1,0,0,0,3,258,1,0,0,0,
5,267,1,0,0,0,7,275,1,0,0,0,9,282,1,0,0,0,11,295,1,0,0,0,13,304,1,0,0,0,
15,324,1,0,0,0,17,328,1,0,0,0,19,332,1,0,0,0,21,334,1,0,0,0,23,337,1,0,0,
0,25,340,1,0,0,0,27,342,1,0,0,0,29,344,1,0,0,0,31,346,1,0,0,0,33,348,1,0,
0,0,35,350,1,0,0,0,37,356,1,0,0,0,39,361,1,0,0,0,41,364,1,0,0,0,43,370,1,
0,0,0,45,376,1,0,0,0,47,386,1,0,0,0,49,392,1,0,0,0,51,400,1,0,0,0,53,411,
1,0,0,0,55,421,1,0,0,0,57,429,1,0,0,0,59,433,1,0,0,0,61,441,1,0,0,0,63,449,
1,0,0,0,65,460,1,0,0,0,67,472,1,0,0,0,69,480,1,0,0,0,71,483,1,0,0,0,73,488,
1,0,0,0,75,493,1,0,0,0,77,500,1,0,0,0,79,509,1,0,0,0,81,514,1,0,0,0,83,522,
1,0,0,0,85,526,1,0,0,0,87,535,1,0,0,0,89,542,1,0,0,0,91,547,1,0,0,0,93,550,
1,0,0,0,95,557,1,0,0,0,97,560,1,0,0,0,99,566,1,0,0,0,101,569,1,0,0,0,103,
577,1,0,0,0,105,585,1,0,0,0,107,590,1,0,0,0,109,594,1,0,0,0,111,597,1,0,
0,0,113,600,1,0,0,0,115,612,1,0,0,0,117,619,1,0,0,0,119,626,1,0,0,0,121,
639,1,0,0,0,123,648,1,0,0,0,125,655,1,0,0,0,127,660,1,0,0,0,129,664,1,0,
0,0,131,671,1,0,0,0,133,677,1,0,0,0,135,684,1,0,0,0,137,689,1,0,0,0,139,
696,1,0,0,0,141,704,1,0,0,0,143,716,1,0,0,0,145,721,1,0,0,0,147,725,1,0,
0,0,149,730,1,0,0,0,151,734,1,0,0,0,153,739,1,0,0,0,155,744,1,0,0,0,157,
749,1,0,0,0,159,751,1,0,0,0,161,754,1,0,0,0,163,759,1,0,0,0,165,786,1,0,
0,0,167,796,1,0,0,0,169,798,1,0,0,0,171,816,1,0,0,0,173,818,1,0,0,0,175,
824,1,0,0,0,177,831,1,0,0,0,179,839,1,0,0,0,181,846,1,0,0,0,183,854,1,0,
0,0,185,859,1,0,0,0,187,861,1,0,0,0,189,863,1,0,0,0,191,865,1,0,0,0,193,
867,1,0,0,0,195,869,1,0,0,0,197,871,1,0,0,0,199,873,1,0,0,0,201,875,1,0,
0,0,203,877,1,0,0,0,205,879,1,0,0,0,207,881,1,0,0,0,209,883,1,0,0,0,211,
885,1,0,0,0,213,887,1,0,0,0,215,889,1,0,0,0,217,891,1,0,0,0,219,894,1,0,
0,0,221,897,1,0,0,0,223,900,1,0,0,0,225,903,1,0,0,0,227,906,1,0,0,0,229,
913,1,0,0,0,231,915,1,0,0,0,233,922,1,0,0,0,235,926,1,0,0,0,237,949,1,0,
0,0,239,951,1,0,0,0,241,953,1,0,0,0,243,957,1,0,0,0,245,959,1,0,0,0,247,
970,1,0,0,0,249,985,1,0,0,0,251,1000,1,0,0,0,253,254,5,102,0,0,254,255,5,
114,0,0,255,256,5,111,0,0,256,257,5,109,0,0,257,2,1,0,0,0,258,259,5,115,
0,0,259,260,5,116,0,0,260,261,5,97,0,0,261,262,5,114,0,0,262,263,5,116,0,
0,263,264,5,105,0,0,264,265,5,110,0,0,265,266,5,103,0,0,266,4,1,0,0,0,267,
268,5,101,0,0,268,269,5,120,0,0,269,270,5,105,0,0,270,271,5,116,0,0,271,
272,5,105,0,0,272,273,5,110,0,0,273,274,5,103,0,0,274,6,1,0,0,0,275,276,
5,111,0,0,276,277,5,112,0,0,277,278,5,101,0,0,278,279,5,110,0,0,279,280,
5,101,0,0,280,281,5,100,0,0,281,8,1,0,0,0,282,283,5,98,0,0,283,284,5,108,
0,0,284,285,5,111,0,0,285,286,5,99,0,0,286,287,5,107,0,0,287,288,5,77,0,
0,288,289,5,101,0,0,289,290,5,115,0,0,290,291,5,115,0,0,291,292,5,97,0,0,
292,293,5,103,0,0,293,294,5,101,0,0,294,10,1,0,0,0,295,296,5,97,0,0,296,
297,5,109,0,0,297,298,5,100,0,0,298,299,5,86,0,0,299,300,5,111,0,0,300,301,
5,105,0,0,301,302,5,99,0,0,302,303,5,101,0,0,303,12,1,0,0,0,304,305,8,0,
0,0,305,14,1,0,0,0,306,310,3,203,101,0,307,309,3,19,9,0,308,307,1,0,0,0,
309,312,1,0,0,0,310,308,1,0,0,0,310,311,1,0,0,0,311,313,1,0,0,0,312,310,
1,0,0,0,313,314,3,203,101,0,314,325,1,0,0,0,315,319,3,201,100,0,316,318,
3,17,8,0,317,316,1,0,0,0,318,321,1,0,0,0,319,317,1,0,0,0,319,320,1,0,0,0,
320,322,1,0,0,0,321,319,1,0,0,0,322,323,3,201,100,0,323,325,1,0,0,0,324,
306,1,0,0,0,324,315,1,0,0,0,325,16,1,0,0,0,326,329,3,13,6,0,327,329,3,23,
11,0,328,326,1,0,0,0,328,327,1,0,0,0,329,18,1,0,0,0,330,333,8,1,0,0,331,
333,3,21,10,0,332,330,1,0,0,0,332,331,1,0,0,0,333,20,1,0,0,0,334,335,5,92,
0,0,335,336,7,1,0,0,336,22,1,0,0,0,337,338,5,92,0,0,338,339,7,2,0,0,339,
24,1,0,0,0,340,341,5,43,0,0,341,26,1,0,0,0,342,343,5,45,0,0,343,28,1,0,0,
0,344,345,5,42,0,0,345,30,1,0,0,0,346,347,5,47,0,0,347,32,1,0,0,0,348,349,
5,37,0,0,349,34,1,0,0,0,350,351,5,97,0,0,351,352,5,112,0,0,352,353,5,112,
0,0,353,354,5,108,0,0,354,355,5,121,0,0,355,36,1,0,0,0,356,357,5,119,0,0,
357,358,5,105,0,0,358,359,5,116,0,0,359,360,5,104,0,0,360,38,1,0,0,0,361,
362,5,97,0,0,362,363,5,115,0,0,363,40,1,0,0,0,364,365,5,97,0,0,365,366,5,
115,0,0,366,367,5,121,0,0,367,368,5,110,0,0,368,369,5,99,0,0,369,42,1,0,
0,0,370,371,5,98,0,0,371,372,5,108,0,0,372,373,5,111,0,0,373,374,5,99,0,
0,374,375,5,107,0,0,375,44,1,0,0,0,376,377,5,98,0,0,377,378,5,108,0,0,378,
379,5,111,0,0,379,380,5,99,0,0,380,381,5,107,0,0,381,382,5,99,0,0,382,383,
5,97,0,0,383,384,5,108,0,0,384,385,5,108,0,0,385,46,1,0,0,0,386,387,5,99,
0,0,387,388,5,97,0,0,388,389,5,116,0,0,389,390,5,99,0,0,390,391,5,104,0,
0,391,48,1,0,0,0,392,393,5,99,0,0,393,394,5,111,0,0,394,395,5,109,0,0,395,
396,5,109,0,0,396,397,5,101,0,0,397,398,5,110,0,0,398,399,5,116,0,0,399,
50,1,0,0,0,400,401,5,99,0,0,401,402,5,111,0,0,402,403,5,110,0,0,403,404,
5,100,0,0,404,405,5,105,0,0,405,406,5,116,0,0,406,407,5,105,0,0,407,408,
5,111,0,0,408,409,5,110,0,0,409,410,5,115,0,0,410,52,1,0,0,0,411,412,5,99,
0,0,412,413,5,111,0,0,413,414,5,110,0,0,414,415,5,102,0,0,415,416,5,105,
0,0,416,417,5,100,0,0,417,418,5,101,0,0,418,419,5,110,0,0,419,420,5,116,
0,0,420,54,1,0,0,0,421,422,5,99,0,0,422,423,5,111,0,0,423,424,5,110,0,0,
424,425,5,116,0,0,425,426,5,101,0,0,426,427,5,120,0,0,427,428,5,116,0,0,
428,56,1,0,0,0,429,430,5,99,0,0,430,431,5,111,0,0,431,432,5,119,0,0,432,
58,1,0,0,0,433,434,5,100,0,0,434,435,5,101,0,0,435,436,5,102,0,0,436,437,
5,97,0,0,437,438,5,117,0,0,438,439,5,108,0,0,439,440,5,116,0,0,440,60,1,
0,0,0,441,442,5,100,0,0,442,443,5,101,0,0,443,444,5,102,0,0,444,445,5,105,
0,0,445,446,5,110,0,0,446,447,5,101,0,0,447,448,5,100,0,0,448,62,1,0,0,0,
449,450,5,100,0,0,450,451,5,105,0,0,451,452,5,103,0,0,452,453,5,114,0,0,
453,454,5,101,0,0,454,455,5,115,0,0,455,456,5,115,0,0,456,457,5,105,0,0,
457,458,5,111,0,0,458,459,5,110,0,0,459,64,1,0,0,0,460,461,5,100,0,0,461,
462,5,105,0,0,462,463,5,103,0,0,463,464,5,114,0,0,464,465,5,101,0,0,465,
466,5,115,0,0,466,467,5,115,0,0,467,468,5,105,0,0,468,469,5,111,0,0,469,
470,5,110,0,0,470,471,5,115,0,0,471,66,1,0,0,0,472,473,5,100,0,0,473,474,
5,105,0,0,474,475,5,115,0,0,475,476,5,97,0,0,476,477,5,98,0,0,477,478,5,
108,0,0,478,479,5,101,0,0,479,68,1,0,0,0,480,481,5,100,0,0,481,482,5,111,
0,0,482,70,1,0,0,0,483,484,5,100,0,0,484,485,5,111,0,0,485,486,5,110,0,0,
486,487,5,101,0,0,487,72,1,0,0,0,488,489,5,101,0,0,489,490,5,108,0,0,490,
491,5,115,0,0,491,492,5,101,0,0,492,74,1,0,0,0,493,494,5,101,0,0,494,495,
5,110,0,0,495,496,5,97,0,0,496,497,5,98,0,0,497,498,5,108,0,0,498,499,5,
101,0,0,499,76,1,0,0,0,500,501,5,101,0,0,501,502,5,120,0,0,502,503,5,116,
0,0,503,504,5,101,0,0,504,505,5,114,0,0,505,506,5,110,0,0,506,507,5,97,0,
0,507,508,5,108,0,0,508,78,1,0,0,0,509,510,5,101,0,0,510,511,5,120,0,0,511,
512,5,105,0,0,512,513,5,116,0,0,513,80,1,0,0,0,514,515,5,102,0,0,515,516,
5,105,0,0,516,517,5,110,0,0,517,518,5,97,0,0,518,519,5,108,0,0,519,520,5,
108,0,0,520,521,5,121,0,0,521,82,1,0,0,0,522,523,5,102,0,0,523,524,5,111,
0,0,524,525,5,114,0,0,525,84,1,0,0,0,526,527,5,102,0,0,527,528,5,117,0,0,
528,529,5,110,0,0,529,530,5,99,0,0,530,531,5,116,0,0,531,532,5,105,0,0,532,
533,5,111,0,0,533,534,5,110,0,0,534,86,1,0,0,0,535,536,5,103,0,0,536,537,
5,108,0,0,537,538,5,111,0,0,538,539,5,98,0,0,539,540,5,97,0,0,540,541,5,
108,0,0,541,88,1,0,0,0,542,543,5,103,0,0,543,544,5,111,0,0,544,545,5,116,
0,0,545,546,5,111,0,0,546,90,1,0,0,0,547,548,5,105,0,0,548,549,5,102,0,0,
549,92,1,0,0,0,550,551,5,105,0,0,551,552,5,109,0,0,552,553,5,112,0,0,553,
554,5,111,0,0,554,555,5,114,0,0,555,556,5,116,0,0,556,94,1,0,0,0,557,558,
5,105,0,0,558,559,5,110,0,0,559,96,1,0,0,0,560,561,5,105,0,0,561,562,5,110,
0,0,562,563,5,112,0,0,563,564,5,117,0,0,564,565,5,116,0,0,565,98,1,0,0,0,
566,567,5,105,0,0,567,568,5,115,0,0,568,100,1,0,0,0,569,570,5,108,0,0,570,
571,5,105,0,0,571,572,5,98,0,0,572,573,5,114,0,0,573,574,5,97,0,0,574,575,
5,114,0,0,575,576,5,121,0,0,576,102,1,0,0,0,577,578,5,108,0,0,578,579,5,
111,0,0,579,580,5,103,0,0,580,581,5,101,0,0,581,582,5,120,0,0,582,583,5,
112,0,0,583,584,5,114,0,0,584,104,1,0,0,0,585,586,5,110,0,0,586,587,5,111,
0,0,587,588,5,100,0,0,588,589,5,101,0,0,589,106,1,0,0,0,590,591,5,110,0,
0,591,592,5,111,0,0,592,593,5,116,0,0,593,108,1,0,0,0,594,595,5,111,0,0,
595,596,5,102,0,0,596,110,1,0,0,0,597,598,5,111,0,0,598,599,5,110,0,0,599,
112,1,0,0,0,600,601,5,111,0,0,601,602,5,110,0,0,602,603,5,100,0,0,603,604,
5,105,0,0,604,605,5,103,0,0,605,606,5,114,0,0,606,607,5,101,0,0,607,608,
5,116,0,0,608,609,5,117,0,0,609,610,5,114,0,0,610,611,5,110,0,0,611,114,
1,0,0,0,612,613,5,111,0,0,613,614,5,110,0,0,614,615,5,101,0,0,615,616,5,
120,0,0,616,617,5,105,0,0,617,618,5,116,0,0,618,116,1,0,0,0,619,620,5,111,
0,0,620,621,5,117,0,0,621,622,5,116,0,0,622,623,5,112,0,0,623,624,5,117,
0,0,624,625,5,116,0,0,625,118,1,0,0,0,626,627,5,112,0,0,627,628,5,114,0,
0,628,629,5,101,0,0,629,630,5,112,0,0,630,631,5,114,0,0,631,632,5,111,0,
0,632,633,5,99,0,0,633,634,5,101,0,0,634,635,5,115,0,0,635,636,5,115,0,0,
636,637,5,111,0,0,637,638,5,114,0,0,638,120,1,0,0,0,639,640,5,112,0,0,640,
641,5,114,0,0,641,642,5,105,0,0,642,643,5,111,0,0,643,644,5,114,0,0,644,
645,5,105,0,0,645,646,5,116,0,0,646,647,5,121,0,0,647,122,1,0,0,0,648,649,
5,114,0,0,649,650,5,101,0,0,650,651,5,116,0,0,651,652,5,117,0,0,652,653,
5,114,0,0,653,654,5,110,0,0,654,124,1,0,0,0,655,656,5,115,0,0,656,657,5,
97,0,0,657,658,5,118,0,0,658,659,5,101,0,0,659,126,1,0,0,0,660,661,5,115,
0,0,661,662,5,101,0,0,662,663,5,116,0,0,663,128,1,0,0,0,664,665,5,115,0,
0,665,666,5,104,0,0,666,667,5,97,0,0,667,668,5,114,0,0,668,669,5,101,0,0,
669,670,5,100,0,0,670,130,1,0,0,0,671,672,5,115,0,0,672,673,5,116,0,0,673,
674,5,97,0,0,674,675,5,114,0,0,675,676,5,116,0,0,676,132,1,0,0,0,677,678,
5,115,0,0,678,679,5,116,0,0,679,680,5,97,0,0,680,681,5,116,0,0,681,682,5,
105,0,0,682,683,5,99,0,0,683,134,1,0,0,0,684,685,5,115,0,0,685,686,5,121,
0,0,686,687,5,110,0,0,687,688,5,99,0,0,688,136,1,0,0,0,689,690,5,115,0,0,
690,691,5,121,0,0,691,692,5,115,0,0,692,693,5,116,0,0,693,694,5,101,0,0,
694,695,5,109,0,0,695,138,1,0,0,0,696,697,5,116,0,0,697,698,5,105,0,0,698,
699,5,109,0,0,699,700,5,101,0,0,700,701,5,111,0,0,701,702,5,117,0,0,702,
703,5,116,0,0,703,140,1,0,0,0,704,705,5,116,0,0,705,706,5,114,0,0,706,707,
5,97,0,0,707,708,5,110,0,0,708,709,5,115,0,0,709,710,5,105,0,0,710,711,5,
116,0,0,711,712,5,105,0,0,712,713,5,111,0,0,713,714,5,110,0,0,714,715,5,
115,0,0,715,142,1,0,0,0,716,717,5,116,0,0,717,718,5,97,0,0,718,719,5,103,
0,0,719,720,5,115,0,0,720,144,1,0,0,0,721,722,5,116,0,0,722,723,5,114,0,
0,723,724,5,121,0,0,724,146,1,0,0,0,725,726,5,116,0,0,726,727,5,121,0,0,
727,728,5,112,0,0,728,729,5,101,0,0,729,148,1,0,0,0,730,731,5,118,0,0,731,
732,5,97,0,0,732,733,5,114,0,0,733,150,1,0,0,0,734,735,5,119,0,0,735,736,
5,97,0,0,736,737,5,105,0,0,737,738,5,116,0,0,738,152,1,0,0,0,739,740,5,119,
0,0,740,741,5,104,0,0,741,742,5,101,0,0,742,743,5,110,0,0,743,154,1,0,0,
0,744,745,5,102,0,0,745,746,5,105,0,0,746,747,5,108,0,0,747,748,5,108,0,
0,748,156,1,0,0,0,749,750,5,35,0,0,750,158,1,0,0,0,751,752,5,36,0,0,752,
160,1,0,0,0,753,755,3,27,13,0,754,753,1,0,0,0,754,755,1,0,0,0,755,756,1,
0,0,0,756,757,3,165,82,0,757,162,1,0,0,0,758,760,3,27,13,0,759,758,1,0,0,
0,759,760,1,0,0,0,760,761,1,0,0,0,761,762,3,167,83,0,762,164,1,0,0,0,763,
764,3,167,83,0,764,768,3,213,106,0,765,767,7,3,0,0,766,765,1,0,0,0,767,770,
1,0,0,0,768,766,1,0,0,0,768,769,1,0,0,0,769,772,1,0,0,0,770,768,1,0,0,0,
771,773,3,169,84,0,772,771,1,0,0,0,772,773,1,0,0,0,773,787,1,0,0,0,774,776,
3,213,106,0,775,777,7,3,0,0,776,775,1,0,0,0,777,778,1,0,0,0,778,776,1,0,
0,0,778,779,1,0,0,0,779,781,1,0,0,0,780,782,3,169,84,0,781,780,1,0,0,0,781,
782,1,0,0,0,782,787,1,0,0,0,783,784,3,167,83,0,784,785,3,169,84,0,785,787,
1,0,0,0,786,763,1,0,0,0,786,774,1,0,0,0,786,783,1,0,0,0,787,166,1,0,0,0,
788,797,5,48,0,0,789,793,7,4,0,0,790,792,7,3,0,0,791,790,1,0,0,0,792,795,
1,0,0,0,793,791,1,0,0,0,793,794,1,0,0,0,794,797,1,0,0,0,795,793,1,0,0,0,
796,788,1,0,0,0,796,789,1,0,0,0,797,168,1,0,0,0,798,800,7,5,0,0,799,801,
7,6,0,0,800,799,1,0,0,0,800,801,1,0,0,0,801,803,1,0,0,0,802,804,7,3,0,0,
803,802,1,0,0,0,804,805,1,0,0,0,805,803,1,0,0,0,805,806,1,0,0,0,806,170,
1,0,0,0,807,808,5,116,0,0,808,809,5,114,0,0,809,810,5,117,0,0,810,817,5,
101,0,0,811,812,5,102,0,0,812,813,5,97,0,0,813,814,5,108,0,0,814,815,5,115,
0,0,815,817,5,101,0,0,816,807,1,0,0,0,816,811,1,0,0,0,817,172,1,0,0,0,818,
819,5,101,0,0,819,820,5,109,0,0,820,821,5,112,0,0,821,822,5,116,0,0,822,
823,5,121,0,0,823,174,1,0,0,0,824,825,5,110,0,0,825,826,5,117,0,0,826,827,
5,109,0,0,827,828,5,98,0,0,828,829,5,101,0,0,829,830,5,114,0,0,830,176,1,
0,0,0,831,832,5,98,0,0,832,833,5,111,0,0,833,834,5,111,0,0,834,835,5,108,
0,0,835,836,5,101,0,0,836,837,5,97,0,0,837,838,5,110,0,0,838,178,1,0,0,0,
839,840,5,115,0,0,840,841,5,116,0,0,841,842,5,114,0,0,842,843,5,105,0,0,
843,844,5,110,0,0,844,845,5,103,0,0,845,180,1,0,0,0,846,847,5,117,0,0,847,
848,5,110,0,0,848,849,5,107,0,0,849,850,5,110,0,0,850,851,5,111,0,0,851,
852,5,119,0,0,852,853,5,110,0,0,853,182,1,0,0,0,854,855,5,110,0,0,855,856,
5,117,0,0,856,857,5,108,0,0,857,858,5,108,0,0,858,184,1,0,0,0,859,860,5,
93,0,0,860,186,1,0,0,0,861,862,5,91,0,0,862,188,1,0,0,0,863,864,5,62,0,0,
864,190,1,0,0,0,865,866,5,60,0,0,866,192,1,0,0,0,867,868,5,41,0,0,868,194,
1,0,0,0,869,870,5,40,0,0,870,196,1,0,0,0,871,872,5,125,0,0,872,198,1,0,0,
0,873,874,5,123,0,0,874,200,1,0,0,0,875,876,5,34,0,0,876,202,1,0,0,0,877,
878,5,96,0,0,878,204,1,0,0,0,879,880,5,58,0,0,880,206,1,0,0,0,881,882,5,
59,0,0,882,208,1,0,0,0,883,884,5,44,0,0,884,210,1,0,0,0,885,886,5,63,0,0,
886,212,1,0,0,0,887,888,5,46,0,0,888,214,1,0,0,0,889,890,5,61,0,0,890,216,
1,0,0,0,891,892,5,43,0,0,892,893,5,61,0,0,893,218,1,0,0,0,894,895,5,45,0,
0,895,896,5,61,0,0,896,220,1,0,0,0,897,898,5,61,0,0,898,899,5,61,0,0,899,
222,1,0,0,0,900,901,5,33,0,0,901,902,5,61,0,0,902,224,1,0,0,0,903,904,5,
62,0,0,904,905,5,61,0,0,905,226,1,0,0,0,906,907,5,60,0,0,907,908,5,61,0,
0,908,228,1,0,0,0,909,910,5,124,0,0,910,914,5,124,0,0,911,912,5,111,0,0,
912,914,5,114,0,0,913,909,1,0,0,0,913,911,1,0,0,0,914,230,1,0,0,0,915,916,
5,124,0,0,916,232,1,0,0,0,917,918,5,38,0,0,918,923,5,38,0,0,919,920,5,97,
0,0,920,921,5,110,0,0,921,923,5,100,0,0,922,917,1,0,0,0,922,919,1,0,0,0,
923,234,1,0,0,0,924,927,5,33,0,0,925,927,3,107,53,0,926,924,1,0,0,0,926,
925,1,0,0,0,927,236,1,0,0,0,928,930,5,64,0,0,929,928,1,0,0,0,929,930,1,0,
0,0,930,931,1,0,0,0,931,935,3,241,120,0,932,934,3,243,121,0,933,932,1,0,
0,0,934,937,1,0,0,0,935,933,1,0,0,0,935,936,1,0,0,0,936,950,1,0,0,0,937,
935,1,0,0,0,938,940,5,64,0,0,939,938,1,0,0,0,939,940,1,0,0,0,940,941,1,0,
0,0,941,942,3,239,119,0,942,946,3,241,120,0,943,945,3,243,121,0,944,943,
1,0,0,0,945,948,1,0,0,0,946,944,1,0,0,0,946,947,1,0,0,0,947,950,1,0,0,0,
948,946,1,0,0,0,949,929,1,0,0,0,949,939,1,0,0,0,950,238,1,0,0,0,951,952,
5,95,0,0,952,240,1,0,0,0,953,954,7,7,0,0,954,242,1,0,0,0,955,958,3,241,120,
0,956,958,3,239,119,0,957,955,1,0,0,0,957,956,1,0,0,0,958,244,1,0,0,0,959,
960,5,47,0,0,960,961,5,47,0,0,961,965,1,0,0,0,962,964,8,8,0,0,963,962,1,
0,0,0,964,967,1,0,0,0,965,963,1,0,0,0,965,966,1,0,0,0,966,968,1,0,0,0,967,
965,1,0,0,0,968,969,6,122,0,0,969,246,1,0,0,0,970,971,5,47,0,0,971,972,5,
42,0,0,972,973,5,42,0,0,973,977,1,0,0,0,974,976,9,0,0,0,975,974,1,0,0,0,
976,979,1,0,0,0,977,978,1,0,0,0,977,975,1,0,0,0,978,980,1,0,0,0,979,977,
1,0,0,0,980,981,5,42,0,0,981,982,5,47,0,0,982,983,1,0,0,0,983,984,6,123,
1,0,984,248,1,0,0,0,985,986,5,47,0,0,986,987,5,42,0,0,987,991,1,0,0,0,988,
990,9,0,0,0,989,988,1,0,0,0,990,993,1,0,0,0,991,992,1,0,0,0,991,989,1,0,
0,0,992,994,1,0,0,0,993,991,1,0,0,0,994,995,5,42,0,0,995,996,5,47,0,0,996,
997,1,0,0,0,997,998,6,124,0,0,998,250,1,0,0,0,999,1001,7,9,0,0,1000,999,
1,0,0,0,1001,1002,1,0,0,0,1002,1000,1,0,0,0,1002,1003,1,0,0,0,1003,1004,
1,0,0,0,1004,1005,6,125,2,0,1005,252,1,0,0,0,31,0,310,319,324,328,332,754,
759,768,772,778,781,786,793,796,800,805,816,913,922,926,929,935,939,946,
949,957,965,977,991,1002,3,0,1,0,0,2,0,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class graphLexer extends antlr4.Lexer {

    static grammarFileName = "graph.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'from'", "'starting'", "'exiting'", "'opened'", 
                         "'blockMessage'", "'amdVoice'", null, "'+'", "'-'", 
                         "'*'", "'/'", "'%'", "'apply'", "'with'", "'as'", 
                         "'async'", "'block'", "'blockcall'", "'catch'", 
                         "'comment'", "'conditions'", "'confident'", "'context'", 
                         "'cow'", "'default'", "'defined'", "'digression'", 
                         "'digressions'", "'disable'", "'do'", "'done'", 
                         "'else'", "'enable'", "'external'", "'exit'", "'finally'", 
                         "'for'", "'function'", "'global'", "'goto'", "'if'", 
                         "'import'", "'in'", "'input'", "'is'", "'library'", 
                         "'logexpr'", "'node'", "'not'", "'of'", "'on'", 
                         "'ondigreturn'", "'onexit'", "'output'", "'preprocessor'", 
                         "'priority'", "'return'", "'save'", "'set'", "'shared'", 
                         "'start'", "'static'", "'sync'", "'system'", "'timeout'", 
                         "'transitions'", "'tags'", "'try'", "'type'", "'var'", 
                         "'wait'", "'when'", "'fill'", "'#'", "'$'", null, 
                         null, null, "'empty'", "'number'", "'boolean'", 
                         "'string'", "'unknown'", "'null'", "']'", "'['", 
                         "'>'", "'<'", "')'", "'('", "'}'", "'{'", "'\"'", 
                         "'`'", "':'", "';'", "','", "'?'", "'.'", "'='", 
                         "'+='", "'-='", "'=='", "'!='", "'>='", "'<='", 
                         null, "'|'" ];
	static symbolicNames = [ null, null, null, null, null, null, null, "StringLiteral", 
                          "PLUS", "MINUS", "STAR", "SLASH", "PERCENT", "APPLY", 
                          "WITH", "AS", "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", 
                          "COMMENT", "CONDITIONS", "CONFIDENT", "CONTEXT", 
                          "COW", "DEFAULT", "DEFINED", "DIGRESSION", "DIGRESSIONS", 
                          "DISABLE", "DO", "DONE", "ELSE", "ENABLE", "EXTERNAL", 
                          "EXIT", "FINALLY", "FOR", "FUNCTION", "GLOBAL", 
                          "GOTO", "IF", "IMPORT", "IN", "INPUT", "IS", "LIBRARY", 
                          "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                          "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", 
                          "RETURN", "SAVE", "SET", "SHARED", "START", "STATIC", 
                          "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", 
                          "TRY", "TYPE", "VAR", "WAIT", "WHEN", "FILL", 
                          "BuiltinMark", "ContextMark", "DecimalLiteral", 
                          "DecimalIntegerLiteral", "BooleanLiteral", "Empty", 
                          "Number", "Boolean", "String", "Unknown", "NULL", 
                          "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", "RPAREN", 
                          "LPAREN", "RCURL", "LCURL", "QUOTE", "BACKTRICK", 
                          "COLON", "SEMICOLON", "COMMA", "QMARK", "PERIOD", 
                          "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", "NotEqualOp", 
                          "GreaterEqualOp", "LessEqualOp", "OrOp", "TypeOr", 
                          "AndOp", "NotOperator", "ID", "LineComment", "DocsComment", 
                          "BlockComment", "Whitespace" ];
	static ruleNames = [ "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "SafeCodePoint", 
                      "StringLiteral", "StringCharacter", "MultilineStringCharacter", 
                      "MultilineEscapeSequence", "EscapeSequence", "PLUS", 
                      "MINUS", "STAR", "SLASH", "PERCENT", "APPLY", "WITH", 
                      "AS", "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", "COMMENT", 
                      "CONDITIONS", "CONFIDENT", "CONTEXT", "COW", "DEFAULT", 
                      "DEFINED", "DIGRESSION", "DIGRESSIONS", "DISABLE", 
                      "DO", "DONE", "ELSE", "ENABLE", "EXTERNAL", "EXIT", 
                      "FINALLY", "FOR", "FUNCTION", "GLOBAL", "GOTO", "IF", 
                      "IMPORT", "IN", "INPUT", "IS", "LIBRARY", "LOGEXPR", 
                      "NODE", "NOT", "OF", "ON", "ONDIGRETURN", "ONEXIT", 
                      "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", "SAVE", 
                      "SET", "SHARED", "START", "STATIC", "SYNC", "SYSTEM", 
                      "TIMEOUT", "TRANSITIONS", "TAGS", "TRY", "TYPE", "VAR", 
                      "WAIT", "WHEN", "FILL", "BuiltinMark", "ContextMark", 
                      "DecimalLiteral", "DecimalIntegerLiteral", "DecimalLiteralAbsoluteValue", 
                      "DecimalIntegerLiteralAbsoluteValue", "ExponentPart", 
                      "BooleanLiteral", "Empty", "Number", "Boolean", "String", 
                      "Unknown", "NULL", "RBRACKET", "LBRACKET", "RANGLE", 
                      "LANGLE", "RPAREN", "LPAREN", "RCURL", "LCURL", "QUOTE", 
                      "BACKTRICK", "COLON", "SEMICOLON", "COMMA", "QMARK", 
                      "PERIOD", "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", 
                      "NotEqualOp", "GreaterEqualOp", "LessEqualOp", "OrOp", 
                      "TypeOr", "AndOp", "NotOperator", "ID", "Underscore", 
                      "NonUnderscoreIdSymbol", "AnyIdSymbol", "LineComment", 
                      "DocsComment", "BlockComment", "Whitespace" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

graphLexer.EOF = antlr4.Token.EOF;
graphLexer.T__0 = 1;
graphLexer.T__1 = 2;
graphLexer.T__2 = 3;
graphLexer.T__3 = 4;
graphLexer.T__4 = 5;
graphLexer.T__5 = 6;
graphLexer.StringLiteral = 7;
graphLexer.PLUS = 8;
graphLexer.MINUS = 9;
graphLexer.STAR = 10;
graphLexer.SLASH = 11;
graphLexer.PERCENT = 12;
graphLexer.APPLY = 13;
graphLexer.WITH = 14;
graphLexer.AS = 15;
graphLexer.ASYNC = 16;
graphLexer.BLOCK = 17;
graphLexer.BLOCKCALL = 18;
graphLexer.CATCH = 19;
graphLexer.COMMENT = 20;
graphLexer.CONDITIONS = 21;
graphLexer.CONFIDENT = 22;
graphLexer.CONTEXT = 23;
graphLexer.COW = 24;
graphLexer.DEFAULT = 25;
graphLexer.DEFINED = 26;
graphLexer.DIGRESSION = 27;
graphLexer.DIGRESSIONS = 28;
graphLexer.DISABLE = 29;
graphLexer.DO = 30;
graphLexer.DONE = 31;
graphLexer.ELSE = 32;
graphLexer.ENABLE = 33;
graphLexer.EXTERNAL = 34;
graphLexer.EXIT = 35;
graphLexer.FINALLY = 36;
graphLexer.FOR = 37;
graphLexer.FUNCTION = 38;
graphLexer.GLOBAL = 39;
graphLexer.GOTO = 40;
graphLexer.IF = 41;
graphLexer.IMPORT = 42;
graphLexer.IN = 43;
graphLexer.INPUT = 44;
graphLexer.IS = 45;
graphLexer.LIBRARY = 46;
graphLexer.LOGEXPR = 47;
graphLexer.NODE = 48;
graphLexer.NOT = 49;
graphLexer.OF = 50;
graphLexer.ON = 51;
graphLexer.ONDIGRETURN = 52;
graphLexer.ONEXIT = 53;
graphLexer.OUTPUT = 54;
graphLexer.PREPROCESSOR = 55;
graphLexer.PRIORITY = 56;
graphLexer.RETURN = 57;
graphLexer.SAVE = 58;
graphLexer.SET = 59;
graphLexer.SHARED = 60;
graphLexer.START = 61;
graphLexer.STATIC = 62;
graphLexer.SYNC = 63;
graphLexer.SYSTEM = 64;
graphLexer.TIMEOUT = 65;
graphLexer.TRANSITIONS = 66;
graphLexer.TAGS = 67;
graphLexer.TRY = 68;
graphLexer.TYPE = 69;
graphLexer.VAR = 70;
graphLexer.WAIT = 71;
graphLexer.WHEN = 72;
graphLexer.FILL = 73;
graphLexer.BuiltinMark = 74;
graphLexer.ContextMark = 75;
graphLexer.DecimalLiteral = 76;
graphLexer.DecimalIntegerLiteral = 77;
graphLexer.BooleanLiteral = 78;
graphLexer.Empty = 79;
graphLexer.Number = 80;
graphLexer.Boolean = 81;
graphLexer.String = 82;
graphLexer.Unknown = 83;
graphLexer.NULL = 84;
graphLexer.RBRACKET = 85;
graphLexer.LBRACKET = 86;
graphLexer.RANGLE = 87;
graphLexer.LANGLE = 88;
graphLexer.RPAREN = 89;
graphLexer.LPAREN = 90;
graphLexer.RCURL = 91;
graphLexer.LCURL = 92;
graphLexer.QUOTE = 93;
graphLexer.BACKTRICK = 94;
graphLexer.COLON = 95;
graphLexer.SEMICOLON = 96;
graphLexer.COMMA = 97;
graphLexer.QMARK = 98;
graphLexer.PERIOD = 99;
graphLexer.EQUALSIGN = 100;
graphLexer.AddOp = 101;
graphLexer.RemoveOp = 102;
graphLexer.EqualOp = 103;
graphLexer.NotEqualOp = 104;
graphLexer.GreaterEqualOp = 105;
graphLexer.LessEqualOp = 106;
graphLexer.OrOp = 107;
graphLexer.TypeOr = 108;
graphLexer.AndOp = 109;
graphLexer.NotOperator = 110;
graphLexer.ID = 111;
graphLexer.LineComment = 112;
graphLexer.DocsComment = 113;
graphLexer.BlockComment = 114;
graphLexer.Whitespace = 115;



