// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by graphParser.
export default class graphListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by graphParser#file.
	enterFile(ctx) {
	}

	// Exit a parse tree produced by graphParser#file.
	exitFile(ctx) {
	}


	// Enter a parse tree produced by graphParser#referenceFile.
	enterReferenceFile(ctx) {
	}

	// Exit a parse tree produced by graphParser#referenceFile.
	exitReferenceFile(ctx) {
	}


	// Enter a parse tree produced by graphParser#expressionFile.
	enterExpressionFile(ctx) {
	}

	// Exit a parse tree produced by graphParser#expressionFile.
	exitExpressionFile(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockContent.
	enterBlockContent(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockContent.
	exitBlockContent(ctx) {
	}


	// Enter a parse tree produced by graphParser#systemDescription.
	enterSystemDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#systemDescription.
	exitSystemDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#systemElementDescription.
	enterSystemElementDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#systemElementDescription.
	exitSystemElementDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#contextDescription.
	enterContextDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#contextDescription.
	exitContextDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#contextElementDescription.
	enterContextElementDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#contextElementDescription.
	exitContextElementDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#externalFunctionDeclaration.
	enterExternalFunctionDeclaration(ctx) {
	}

	// Exit a parse tree produced by graphParser#externalFunctionDeclaration.
	exitExternalFunctionDeclaration(ctx) {
	}


	// Enter a parse tree produced by graphParser#importDefinition.
	enterImportDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#importDefinition.
	exitImportDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#whenDescription.
	enterWhenDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#whenDescription.
	exitWhenDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeDefinition.
	enterTypeDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeDefinition.
	exitTypeDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#functionDescription.
	enterFunctionDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#functionDescription.
	exitFunctionDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#contextFunctionDescription.
	enterContextFunctionDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#contextFunctionDescription.
	exitContextFunctionDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockDeclaration.
	enterBlockDeclaration(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockDeclaration.
	exitBlockDeclaration(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeDeclaration.
	enterNodeDeclaration(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeDeclaration.
	exitNodeDeclaration(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionDeclaration.
	enterDigressionDeclaration(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionDeclaration.
	exitDigressionDeclaration(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionContent.
	enterDigressionContent(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionContent.
	exitDigressionContent(ctx) {
	}


	// Enter a parse tree produced by graphParser#comment.
	enterComment(ctx) {
	}

	// Exit a parse tree produced by graphParser#comment.
	exitComment(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeContent.
	enterNodeContent(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeContent.
	exitNodeContent(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeOnExitSection.
	enterNodeOnExitSection(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeOnExitSection.
	exitNodeOnExitSection(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeOnExitSectionItem.
	enterNodeOnExitSectionItem(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeOnExitSectionItem.
	exitNodeOnExitSectionItem(ctx) {
	}


	// Enter a parse tree produced by graphParser#onExitDoWithConfident.
	enterOnExitDoWithConfident(ctx) {
	}

	// Exit a parse tree produced by graphParser#onExitDoWithConfident.
	exitOnExitDoWithConfident(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeOnDigressionReturnSection.
	enterNodeOnDigressionReturnSection(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeOnDigressionReturnSection.
	exitNodeOnDigressionReturnSection(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeTransitionSection.
	enterNodeTransitionSection(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeTransitionSection.
	exitNodeTransitionSection(ctx) {
	}


	// Enter a parse tree produced by graphParser#namedTransitionDefinition.
	enterNamedTransitionDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#namedTransitionDefinition.
	exitNamedTransitionDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#transitionDefinition.
	enterTransitionDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#transitionDefinition.
	exitTransitionDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#transitionDefinitionOnMessage.
	enterTransitionDefinitionOnMessage(ctx) {
	}

	// Exit a parse tree produced by graphParser#transitionDefinitionOnMessage.
	exitTransitionDefinitionOnMessage(ctx) {
	}


	// Enter a parse tree produced by graphParser#timeoutTransitionDefinition.
	enterTimeoutTransitionDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#timeoutTransitionDefinition.
	exitTimeoutTransitionDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#conditionalTransitionDefinition.
	enterConditionalTransitionDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#conditionalTransitionDefinition.
	exitConditionalTransitionDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#unconditionalTransitionDefinition.
	enterUnconditionalTransitionDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#unconditionalTransitionDefinition.
	exitUnconditionalTransitionDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#doSection.
	enterDoSection(ctx) {
	}

	// Exit a parse tree produced by graphParser#doSection.
	exitDoSection(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockBody.
	enterBlockBody(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockBody.
	exitBlockBody(ctx) {
	}


	// Enter a parse tree produced by graphParser#oneOrGroupedCommand.
	enterOneOrGroupedCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#oneOrGroupedCommand.
	exitOneOrGroupedCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeCommand.
	enterNodeCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeCommand.
	exitNodeCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#cycleCommand.
	enterCycleCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#cycleCommand.
	exitCycleCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#statement.
	enterStatement(ctx) {
	}

	// Exit a parse tree produced by graphParser#statement.
	exitStatement(ctx) {
	}


	// Enter a parse tree produced by graphParser#statementBody.
	enterStatementBody(ctx) {
	}

	// Exit a parse tree produced by graphParser#statementBody.
	exitStatementBody(ctx) {
	}


	// Enter a parse tree produced by graphParser#logExpressionTypeCommand.
	enterLogExpressionTypeCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#logExpressionTypeCommand.
	exitLogExpressionTypeCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#enableDigression.
	enterEnableDigression(ctx) {
	}

	// Exit a parse tree produced by graphParser#enableDigression.
	exitEnableDigression(ctx) {
	}


	// Enter a parse tree produced by graphParser#disableDigression.
	enterDisableDigression(ctx) {
	}

	// Exit a parse tree produced by graphParser#disableDigression.
	exitDisableDigression(ctx) {
	}


	// Enter a parse tree produced by graphParser#saveDigression.
	enterSaveDigression(ctx) {
	}

	// Exit a parse tree produced by graphParser#saveDigression.
	exitSaveDigression(ctx) {
	}


	// Enter a parse tree produced by graphParser#applyDigression.
	enterApplyDigression(ctx) {
	}

	// Exit a parse tree produced by graphParser#applyDigression.
	exitApplyDigression(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionSpec.
	enterDigressionSpec(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionSpec.
	exitDigressionSpec(ctx) {
	}


	// Enter a parse tree produced by graphParser#exitCommand.
	enterExitCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#exitCommand.
	exitExitCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#returnCommand.
	enterReturnCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#returnCommand.
	exitReturnCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#variableDefinition.
	enterVariableDefinition(ctx) {
	}

	// Exit a parse tree produced by graphParser#variableDefinition.
	exitVariableDefinition(ctx) {
	}


	// Enter a parse tree produced by graphParser#variableDeclaration.
	enterVariableDeclaration(ctx) {
	}

	// Exit a parse tree produced by graphParser#variableDeclaration.
	exitVariableDeclaration(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeDescription.
	enterTypeDescription(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeDescription.
	exitTypeDescription(ctx) {
	}


	// Enter a parse tree produced by graphParser#conditionalCommand.
	enterConditionalCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#conditionalCommand.
	exitConditionalCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#foreachCommand.
	enterForeachCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#foreachCommand.
	exitForeachCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#waitCommand.
	enterWaitCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#waitCommand.
	exitWaitCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#selectedActivatedTransitionList.
	enterSelectedActivatedTransitionList(ctx) {
	}

	// Exit a parse tree produced by graphParser#selectedActivatedTransitionList.
	exitSelectedActivatedTransitionList(ctx) {
	}


	// Enter a parse tree produced by graphParser#allActivatedTransitionList.
	enterAllActivatedTransitionList(ctx) {
	}

	// Exit a parse tree produced by graphParser#allActivatedTransitionList.
	exitAllActivatedTransitionList(ctx) {
	}


	// Enter a parse tree produced by graphParser#gotoCommand.
	enterGotoCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#gotoCommand.
	exitGotoCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#setCommand.
	enterSetCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#setCommand.
	exitSetCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#assignOpCommand.
	enterAssignOpCommand(ctx) {
	}

	// Exit a parse tree produced by graphParser#assignOpCommand.
	exitAssignOpCommand(ctx) {
	}


	// Enter a parse tree produced by graphParser#lValueReferenceExpression.
	enterLValueReferenceExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#lValueReferenceExpression.
	exitLValueReferenceExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#lValueParenthesizedExpression.
	enterLValueParenthesizedExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#lValueParenthesizedExpression.
	exitLValueParenthesizedExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#lValueDotAccessExpression.
	enterLValueDotAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#lValueDotAccessExpression.
	exitLValueDotAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#lValueLiteralIndexAccessExpression.
	enterLValueLiteralIndexAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#lValueLiteralIndexAccessExpression.
	exitLValueLiteralIndexAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#lValueExpressionIndexAccessExpression.
	enterLValueExpressionIndexAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#lValueExpressionIndexAccessExpression.
	exitLValueExpressionIndexAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#ternaryOpExpression.
	enterTernaryOpExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#ternaryOpExpression.
	exitTernaryOpExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#expressionIndexAccessExpression.
	enterExpressionIndexAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#expressionIndexAccessExpression.
	exitExpressionIndexAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#nullCollateExpression.
	enterNullCollateExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#nullCollateExpression.
	exitNullCollateExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#booleanLiteralExpression.
	enterBooleanLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#booleanLiteralExpression.
	exitBooleanLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#numberLiteralExpression.
	enterNumberLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#numberLiteralExpression.
	exitNumberLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#binaryOpExpression.
	enterBinaryOpExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#binaryOpExpression.
	exitBinaryOpExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#optionalCallDotAccessExpression.
	enterOptionalCallDotAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#optionalCallDotAccessExpression.
	exitOptionalCallDotAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#castExpression.
	enterCastExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#castExpression.
	exitCastExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#builtinFunctionCallExpression.
	enterBuiltinFunctionCallExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#builtinFunctionCallExpression.
	exitBuiltinFunctionCallExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#parenthesizedExpression.
	enterParenthesizedExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#parenthesizedExpression.
	exitParenthesizedExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#callDotAccessExpression.
	enterCallDotAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#callDotAccessExpression.
	exitCallDotAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#nullLiteralExpression.
	enterNullLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#nullLiteralExpression.
	exitNullLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#isNullExpression.
	enterIsNullExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#isNullExpression.
	exitIsNullExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionControlExpression.
	enterDigressionControlExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionControlExpression.
	exitDigressionControlExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#tupleLiteralExpression.
	enterTupleLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#tupleLiteralExpression.
	exitTupleLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockFunctionCallExpression.
	enterBlockFunctionCallExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockFunctionCallExpression.
	exitBlockFunctionCallExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#referenceExpression.
	enterReferenceExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#referenceExpression.
	exitReferenceExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#optionalDotAccessExpression.
	enterOptionalDotAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#optionalDotAccessExpression.
	exitOptionalDotAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#objectLiteralExpression.
	enterObjectLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#objectLiteralExpression.
	exitObjectLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#literalIndexAccessExpression.
	enterLiteralIndexAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#literalIndexAccessExpression.
	exitLiteralIndexAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#externFunctionCallExpression.
	enterExternFunctionCallExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#externFunctionCallExpression.
	exitExternFunctionCallExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#stringLiteralExpression.
	enterStringLiteralExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#stringLiteralExpression.
	exitStringLiteralExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#slotFillingCallExpression.
	enterSlotFillingCallExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#slotFillingCallExpression.
	exitSlotFillingCallExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#isDefinedExpression.
	enterIsDefinedExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#isDefinedExpression.
	exitIsDefinedExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#unaryOpExpression.
	enterUnaryOpExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#unaryOpExpression.
	exitUnaryOpExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#dotAccessExpression.
	enterDotAccessExpression(ctx) {
	}

	// Exit a parse tree produced by graphParser#dotAccessExpression.
	exitDotAccessExpression(ctx) {
	}


	// Enter a parse tree produced by graphParser#objectPropertyExpressionPart.
	enterObjectPropertyExpressionPart(ctx) {
	}

	// Exit a parse tree produced by graphParser#objectPropertyExpressionPart.
	exitObjectPropertyExpressionPart(ctx) {
	}


	// Enter a parse tree produced by graphParser#externalFunctionCall.
	enterExternalFunctionCall(ctx) {
	}

	// Exit a parse tree produced by graphParser#externalFunctionCall.
	exitExternalFunctionCall(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockFunctionCall.
	enterBlockFunctionCall(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockFunctionCall.
	exitBlockFunctionCall(ctx) {
	}


	// Enter a parse tree produced by graphParser#slotFillingCall.
	enterSlotFillingCall(ctx) {
	}

	// Exit a parse tree produced by graphParser#slotFillingCall.
	exitSlotFillingCall(ctx) {
	}


	// Enter a parse tree produced by graphParser#builtinFunctionCall.
	enterBuiltinFunctionCall(ctx) {
	}

	// Exit a parse tree produced by graphParser#builtinFunctionCall.
	exitBuiltinFunctionCall(ctx) {
	}


	// Enter a parse tree produced by graphParser#argumentInvokation.
	enterArgumentInvokation(ctx) {
	}

	// Exit a parse tree produced by graphParser#argumentInvokation.
	exitArgumentInvokation(ctx) {
	}


	// Enter a parse tree produced by graphParser#argumentList.
	enterArgumentList(ctx) {
	}

	// Exit a parse tree produced by graphParser#argumentList.
	exitArgumentList(ctx) {
	}


	// Enter a parse tree produced by graphParser#namedArgumentList.
	enterNamedArgumentList(ctx) {
	}

	// Exit a parse tree produced by graphParser#namedArgumentList.
	exitNamedArgumentList(ctx) {
	}


	// Enter a parse tree produced by graphParser#positionalArgumentList.
	enterPositionalArgumentList(ctx) {
	}

	// Exit a parse tree produced by graphParser#positionalArgumentList.
	exitPositionalArgumentList(ctx) {
	}


	// Enter a parse tree produced by graphParser#positionalArgument.
	enterPositionalArgument(ctx) {
	}

	// Exit a parse tree produced by graphParser#positionalArgument.
	exitPositionalArgument(ctx) {
	}


	// Enter a parse tree produced by graphParser#namedArgument.
	enterNamedArgument(ctx) {
	}

	// Exit a parse tree produced by graphParser#namedArgument.
	exitNamedArgument(ctx) {
	}


	// Enter a parse tree produced by graphParser#parameterList.
	enterParameterList(ctx) {
	}

	// Exit a parse tree produced by graphParser#parameterList.
	exitParameterList(ctx) {
	}


	// Enter a parse tree produced by graphParser#parameter.
	enterParameter(ctx) {
	}

	// Exit a parse tree produced by graphParser#parameter.
	exitParameter(ctx) {
	}


	// Enter a parse tree produced by graphParser#builtinReference.
	enterBuiltinReference(ctx) {
	}

	// Exit a parse tree produced by graphParser#builtinReference.
	exitBuiltinReference(ctx) {
	}


	// Enter a parse tree produced by graphParser#localReference.
	enterLocalReference(ctx) {
	}

	// Exit a parse tree produced by graphParser#localReference.
	exitLocalReference(ctx) {
	}


	// Enter a parse tree produced by graphParser#contextReference.
	enterContextReference(ctx) {
	}

	// Exit a parse tree produced by graphParser#contextReference.
	exitContextReference(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionReference.
	enterDigressionReference(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionReference.
	exitDigressionReference(ctx) {
	}


	// Enter a parse tree produced by graphParser#whenId.
	enterWhenId(ctx) {
	}

	// Exit a parse tree produced by graphParser#whenId.
	exitWhenId(ctx) {
	}


	// Enter a parse tree produced by graphParser#nodeId.
	enterNodeId(ctx) {
	}

	// Exit a parse tree produced by graphParser#nodeId.
	exitNodeId(ctx) {
	}


	// Enter a parse tree produced by graphParser#blockId.
	enterBlockId(ctx) {
	}

	// Exit a parse tree produced by graphParser#blockId.
	exitBlockId(ctx) {
	}


	// Enter a parse tree produced by graphParser#digressionId.
	enterDigressionId(ctx) {
	}

	// Exit a parse tree produced by graphParser#digressionId.
	exitDigressionId(ctx) {
	}


	// Enter a parse tree produced by graphParser#transitionName.
	enterTransitionName(ctx) {
	}

	// Exit a parse tree produced by graphParser#transitionName.
	exitTransitionName(ctx) {
	}


	// Enter a parse tree produced by graphParser#referenceName.
	enterReferenceName(ctx) {
	}

	// Exit a parse tree produced by graphParser#referenceName.
	exitReferenceName(ctx) {
	}


	// Enter a parse tree produced by graphParser#memberName.
	enterMemberName(ctx) {
	}

	// Exit a parse tree produced by graphParser#memberName.
	exitMemberName(ctx) {
	}


	// Enter a parse tree produced by graphParser#tagName.
	enterTagName(ctx) {
	}

	// Exit a parse tree produced by graphParser#tagName.
	exitTagName(ctx) {
	}


	// Enter a parse tree produced by graphParser#factName.
	enterFactName(ctx) {
	}

	// Exit a parse tree produced by graphParser#factName.
	exitFactName(ctx) {
	}


	// Enter a parse tree produced by graphParser#fieldName.
	enterFieldName(ctx) {
	}

	// Exit a parse tree produced by graphParser#fieldName.
	exitFieldName(ctx) {
	}


	// Enter a parse tree produced by graphParser#variableName.
	enterVariableName(ctx) {
	}

	// Exit a parse tree produced by graphParser#variableName.
	exitVariableName(ctx) {
	}


	// Enter a parse tree produced by graphParser#argumentName.
	enterArgumentName(ctx) {
	}

	// Exit a parse tree produced by graphParser#argumentName.
	exitArgumentName(ctx) {
	}


	// Enter a parse tree produced by graphParser#parameterName.
	enterParameterName(ctx) {
	}

	// Exit a parse tree produced by graphParser#parameterName.
	exitParameterName(ctx) {
	}


	// Enter a parse tree produced by graphParser#propertyName.
	enterPropertyName(ctx) {
	}

	// Exit a parse tree produced by graphParser#propertyName.
	exitPropertyName(ctx) {
	}


	// Enter a parse tree produced by graphParser#builtinFunctionName.
	enterBuiltinFunctionName(ctx) {
	}

	// Exit a parse tree produced by graphParser#builtinFunctionName.
	exitBuiltinFunctionName(ctx) {
	}


	// Enter a parse tree produced by graphParser#externalFunctionName.
	enterExternalFunctionName(ctx) {
	}

	// Exit a parse tree produced by graphParser#externalFunctionName.
	exitExternalFunctionName(ctx) {
	}


	// Enter a parse tree produced by graphParser#functionName.
	enterFunctionName(ctx) {
	}

	// Exit a parse tree produced by graphParser#functionName.
	exitFunctionName(ctx) {
	}


	// Enter a parse tree produced by graphParser#anyNumber.
	enterAnyNumber(ctx) {
	}

	// Exit a parse tree produced by graphParser#anyNumber.
	exitAnyNumber(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeEof.
	enterTypeEof(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeEof.
	exitTypeEof(ctx) {
	}


	// Enter a parse tree produced by graphParser#type_.
	enterType_(ctx) {
	}

	// Exit a parse tree produced by graphParser#type_.
	exitType_(ctx) {
	}


	// Enter a parse tree produced by graphParser#presentQuestionMark.
	enterPresentQuestionMark(ctx) {
	}

	// Exit a parse tree produced by graphParser#presentQuestionMark.
	exitPresentQuestionMark(ctx) {
	}


	// Enter a parse tree produced by graphParser#noQuestionMark.
	enterNoQuestionMark(ctx) {
	}

	// Exit a parse tree produced by graphParser#noQuestionMark.
	exitNoQuestionMark(ctx) {
	}


	// Enter a parse tree produced by graphParser#objectTypeElementSeparator.
	enterObjectTypeElementSeparator(ctx) {
	}

	// Exit a parse tree produced by graphParser#objectTypeElementSeparator.
	exitObjectTypeElementSeparator(ctx) {
	}


	// Enter a parse tree produced by graphParser#predefinedTypeRef.
	enterPredefinedTypeRef(ctx) {
	}

	// Exit a parse tree produced by graphParser#predefinedTypeRef.
	exitPredefinedTypeRef(ctx) {
	}


	// Enter a parse tree produced by graphParser#arrayType.
	enterArrayType(ctx) {
	}

	// Exit a parse tree produced by graphParser#arrayType.
	exitArrayType(ctx) {
	}


	// Enter a parse tree produced by graphParser#stringLiteralType.
	enterStringLiteralType(ctx) {
	}

	// Exit a parse tree produced by graphParser#stringLiteralType.
	exitStringLiteralType(ctx) {
	}


	// Enter a parse tree produced by graphParser#tupleType.
	enterTupleType(ctx) {
	}

	// Exit a parse tree produced by graphParser#tupleType.
	exitTupleType(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeReference.
	enterTypeReference(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeReference.
	exitTypeReference(ctx) {
	}


	// Enter a parse tree produced by graphParser#angleUnionType.
	enterAngleUnionType(ctx) {
	}

	// Exit a parse tree produced by graphParser#angleUnionType.
	exitAngleUnionType(ctx) {
	}


	// Enter a parse tree produced by graphParser#parenthesisedType.
	enterParenthesisedType(ctx) {
	}

	// Exit a parse tree produced by graphParser#parenthesisedType.
	exitParenthesisedType(ctx) {
	}


	// Enter a parse tree produced by graphParser#objectType.
	enterObjectType(ctx) {
	}

	// Exit a parse tree produced by graphParser#objectType.
	exitObjectType(ctx) {
	}


	// Enter a parse tree produced by graphParser#unknownType.
	enterUnknownType(ctx) {
	}

	// Exit a parse tree produced by graphParser#unknownType.
	exitUnknownType(ctx) {
	}


	// Enter a parse tree produced by graphParser#numberType.
	enterNumberType(ctx) {
	}

	// Exit a parse tree produced by graphParser#numberType.
	exitNumberType(ctx) {
	}


	// Enter a parse tree produced by graphParser#booleanType.
	enterBooleanType(ctx) {
	}

	// Exit a parse tree produced by graphParser#booleanType.
	exitBooleanType(ctx) {
	}


	// Enter a parse tree produced by graphParser#stringType.
	enterStringType(ctx) {
	}

	// Exit a parse tree produced by graphParser#stringType.
	exitStringType(ctx) {
	}


	// Enter a parse tree produced by graphParser#emptyType.
	enterEmptyType(ctx) {
	}

	// Exit a parse tree produced by graphParser#emptyType.
	exitEmptyType(ctx) {
	}


	// Enter a parse tree produced by graphParser#propertySignature.
	enterPropertySignature(ctx) {
	}

	// Exit a parse tree produced by graphParser#propertySignature.
	exitPropertySignature(ctx) {
	}


	// Enter a parse tree produced by graphParser#indexSignature.
	enterIndexSignature(ctx) {
	}

	// Exit a parse tree produced by graphParser#indexSignature.
	exitIndexSignature(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeAnnotation.
	enterTypeAnnotation(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeAnnotation.
	exitTypeAnnotation(ctx) {
	}


	// Enter a parse tree produced by graphParser#typeAlias.
	enterTypeAlias(ctx) {
	}

	// Exit a parse tree produced by graphParser#typeAlias.
	exitTypeAlias(ctx) {
	}



}