import { ErrorListener } from "antlr4";
import { RecognitionException } from "antlr4";
import { Token } from "antlr4";

export type GraphSyntaxError = {
  offendingSymbol: Token;
  line: number;
  column: number;
  message: string;
  error: RecognitionException | null;
};

export class GraphErrorListener extends ErrorListener<Token> {
  private errors: GraphSyntaxError[] = [];
  syntaxError(
    recognizer: any,
    offendingSymbol: Token,
    line: number,
    column: number,
    errorMessage: string,
    error: RecognitionException
  ) {
    this.errors.push({ offendingSymbol, line, column, message: errorMessage, error });
  }

  getErrors(): GraphSyntaxError[] {
    return this.errors;
  }
}
